<template>
  <div 
    :class="{ 'pa-4': breakpoint('xs')}"
    class="messaging pb-8"
  >
    <div
      class="toolbar white rounded-t pa-4 d-flex align-center scrollable x"
      :style="{ top: breakpoint('md') ? '64px' : '56px' }"
    >
      <v-btn-toggle
        active-class="faux-active"
        multiple
        mandatory
        class="status-filter scrollable-x flex-shrink-1 mr-4"
      >
        <v-menu 
          v-for="(filter, key) in filtersState"
          :key="`filter-btn-${key}`"
          :close-on-content-click="false"
          offset-y
          max-width="360"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              height="40"
              outlined
              :class="{ 'text--disabled': filter.disabled, 'active primary primary--text': filter.active }"
              class="filter btn px-4 text-overline"
              v-on="on"
              v-bind="attrs"
              @click.prevent.stop
            >
              {{ filter.label }}
              <v-icon right>
                {{ icons.menu }}
              </v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(option, i) in filter.options"
              :key="`filter-btn-${key}-option-${i}`"
              :class="{ 'primary--text font-weight-bold': option.active }"
              class="text-overline"
              @click="setFilter(key, option.value, !filter.multiple ? null : !option.active)"
            >
              {{ option.text }}
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn-toggle>
      <!-- <v-btn
        text
        color="primary"
        :loading="controller.update.loading"
        :disabled="controller.update.disabled||controller.update.loading"
        @click="select"
      >
        <v-icon left>
          {{ icons.add }}
        </v-icon>
        Adicionar
      </v-btn> -->
      <v-spacer />
      <v-text-field
        v-model="controller.search.query"
        :loading="controller.search.loading"
        :disabled="controller.search.disabled"
        :prepend-icon="icons.search"
        solo
        flat
        dense
        placeholder="Buscar..."
        clearable
        hide-details
        class="search-field field"
        @keyup.enter="get"
      />
    </div>
    <v-simple-table
      fixed-header
      class="list"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th 
              v-for="(column, key) in header"
              :key="'header-'+key"
              :width="'width' in column ? column.width : 'auto'"
              :class="['text-'+('align' in column ? column.align : 'left')]"
            >
              {{ column.title }}
            </th>
          </tr>
        </thead>
        <v-progress-linear
          :active="controller.list.loading"
          height="2"
          indeterminate
          absolute
        />
        <tbody>
          <tr
            v-for="item in list"
            :key="'message-'+item.id"
            class="message-row"
          >
            <td 
              v-for="(column, key) in header"
              :key="'message-'+item.id+'-'+key"
              :class="[{ truncate: 'truncate' in column && column.truncate }, 'text-'+column.align]"
            >
              <v-icon 
                v-if="key=='status'"
                small
              >
                {{ icons.status[item[key]] }}
              </v-icon>
              <span
                v-else-if="key=='content'"
                :title="item.title+' | '+item.text"
              >
                <em>{{ item.title }}</em> | {{ item.text }}
              </span>
              <span
                v-else-if="key=='read'||key=='sent'"
                :title="$options.filters.formatDate(item[key])"
              >
                {{ item[key] | sinceDate }}
              </span>
              <span
                v-else
                :title="item[key]"
              >
                {{ item[key] }}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<style lang="scss">

  .messaging .toolbar {
    position: sticky !important;
    z-index: 3;
  }

  .list {
    position: relative;
  }
  .truncate {
    max-width: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .search-field {
    max-width: 240px;
    background: transparent;
  }
  .toolbar .search-field .v-input__slot {
    background: transparent;
  }

  .messaging .toolbar .status-filter .btn.filter.active {
    border-bottom-color: var(--primary) !important;
    font-weight: 900;
    &:before {
      opacity: .04;
    }
  }
  .messaging .toolbar .status-filter .btn {
    border-bottom: 2px solid !important;
    background: transparent !important;
  }
  .messaging .message-row {
    position: relative;
  }
  .messaging .status-control {
    position: absolute;
    top: -14px;
    left: -38px;
    min-width: 108px;
  }
</style>

<script>
  import services from '@/services'
  import { mdiMagnify, mdiPlus, mdiRefresh, mdiMenuDown, mdiClockOutline, mdiSendOutline, mdiCheckAll } from '@mdi/js';
  import moment from 'moment';
  import {
    sync,
  } from 'vuex-pathify'
  import { get as $get } from '@/api/notifications'


  export default {
    name: 'Messaging',
    metaInfo: {
      title: 'Comunicações'
    },

    data: () => ({
      icons: {
        search: mdiMagnify,
        add: mdiPlus,
        refresh: mdiRefresh, 
        menu: mdiMenuDown,
        status: [mdiClockOutline, mdiSendOutline, mdiCheckAll]
      },
      controller: {
        search: {
          query: null,
          loading: false,
          disabled: false,
        },
        filters: {
          app: {
            label: 'App',
            multiple: false,
            mandatory: true,
            options: ['DRIVER', 'COM','GO']
          },
          type: {
            label: 'Tipo',
            multiple: true,
            mandatory: true,
            options: ['PUSH', 'ALERT','EMAIL']
          },
          status: {
            label: 'Status',
            multiple: true,
            mandatory: false,
            options: [
              {
                text: 'Enviado',
                value: 1,
              },
              {
                text: 'Lido',
                value: 2,
              },
              {
                text: 'Programado',
                value: 0,
              }
            ]
          },
        },
        list: {
          items: {},
          header: {
            'status': {
              title: 'Status',
              width: '5%',
              mobile: true,
            },
            'recipient': {
              title: 'Destinatário',
              width: '10%',
              mobile: true,
            },
            'content': {
              title: 'Conteúdo',
              truncate: true,
              mobile: true,
            },
            'sent': {
              title: 'Enviado',
              width: '10%',
              truncate: true,
            },
            'read': {
              title: 'Lido',
              width: '10%',
              truncate: true,
            }
          },
          min: 0,
          max: 100,
          loading: false,
          disabled: false
        },
      },
    }),

    computed: {
      filters: sync('messaging/state@filters'),
      user: sync('user/data'),
      toast: sync('app/toast'),

      filtersState () {
        const selected = this.filters;
        const filters = this.controller.filters;
        return _.mapValues(filters, (filter, key) => {
          const options =  _.map(filter.options, option => {
            const { text=option, value=option } = option;
            const active = _.isEqual(selected[key], value) || _.indexOf(selected[key], value)>=0;
            return {
              text,
              value,
              active
            }
          });
          const value = _.filter(options, ['active', true]);
          const label = _.isEmpty(value) ? filter.label : _.join(_.map(value, 'text'), ', ');
          return {
            ...filter,
            active: !_.isEmpty(value),
            label,
            options
          };
        });
      },

      header () {
        const isMobile = this.breakpoint(null, 'xs');
        return _.pickBy(this.controller.list.header, column => {
          return !isMobile||(_.has(column, 'mobile')&&column.mobile);
        })
      },

      list () {
        const items = _.cloneDeep(this.controller.list.items);
        let query = this.controller.search.query;
        query = this.isValid(query) ? query.normalize("NFD").replace(/\p{Diacritic}/gu, "") : '';
        let list = this.searchEngine(items, query, true, ['recipient', 'title', 'text']);
        list = !_.isEmpty(list) ? list : this.searchEngine(items, query, false, ['recipient', 'title', 'text']);
        const filters = _.pickBy(this.filtersState, ['active', true]);
        const order = [['sent', 'created'], ['desc', 'desc']];
        return _.orderBy(_.size(filters)>0 ? _.filter(list, item => {
          return _.every(filters, (filter, key) => {
            const values = _.map(_.filter(filter.options, ['active', true]), 'value');
            return _.indexOf(values, item[key])>=0;
          })
        }) : list, ...order);
      },
    },

    watch: {
      filters: {
        deep: true,
        handler (filters) {
          this.get();
        }
      }
    },

    filters: {
      sinceDate (date) {
        return moment(date).isValid() ? moment(date).fromNow() : '–';
      },
      formatDate (date) {
        return moment(date).isValid() ? moment(date).format('DD/MM/YY HH:mm:ss') : '–';
      }
    },

    methods: {
      ...services,
      $get,

      setFilter (key, value, add=null) {
        const filter = this.filters[key];
        const filters = { 
          ...this.filters, 
          [key]: add==null ? value : add ? [...filter, value] : _.without(filter, value)
        };
        this.$set(this, 'filters', filters);
      },
      
      setList (data) {
        _.each(data, item => {
          // const content = { title: item.title, text: item.text };
          const status = !!item.read ? 2 : !!item.sent ? 1 : 0;
          this.$set(this.controller.list.items, item.id, { ...item, status });
        });
      },

      get () {
        this.controller.list.loading = true;
        this.controller.list.updated = false;

        const controller = this.controller;
        const text = controller.search.query;
        const page_min = controller.list.min;
        const page_max = controller.list.max;
        
        this.$get(
          this.user.username,
          this.user.auth.token,
          { ...this.filters, text, page_min, page_max },
          (data) => {
            this.setList(data);
            this.controller.list.updated = true;
          },
          (error) => { 
            if (error.response.status==401) {
              this.getout();
              this.$emit('alert', 'Sua sessão expirou...')
            }else{
              // this.$emit('alert', 'Não foi possível criar a fila, tente novamente.');
            }
          },
          () => { 
            this.controller.list.loading = false;
          }
        )
      },

      openDriver (cpf) {
        this.$router.push({ path: `/drivers?search=${cpf}` });
      }
    },

    mounted () {
      this.get();
    }
  }
</script>